import { Icon } from '@iconify/react';
import { Card } from 'components/common/Card';
import { NextPage } from 'next';
import { trpc } from 'utils/trpc';

// import CreateQuote from '../components/quotes/create/create';
import Layout from '../layouts';

const Home: NextPage = () => {
  const apiKeys = trpc.user.apiKeys.useQuery();
  const user = trpc.auth.getCurrentUser.useQuery();
  return (
    <Layout showSideBar isProtected title="Dashboard">
      <div className="mx-auto my-6 lg:my-20 flex w-full max-w-7xl flex-grow flex-col items-start justify-start px-5 lg:pl-64 ">
        <div className="text-2xl flex">
          Bonjour <Icon icon={'twemoji:waving-hand'} className="ml-2" />
        </div>
        <div className="my-4 flex items-center ">
          <Icon
            icon={'twemoji:calendar'}
            className=" h-12 w-12 lg:h-4 lg:w-4 text-center mx-1"
          />
          <p>
            Vous avez des questions à propos de la génération de facture
            électronique, prenez
            <a
              href="https://cal.com/thibault-le-ouay-ducasse/30min"
              target="_blank"
              rel="noreferrer"
              className="mx-1 hover:text-primary-600  text-primary-500"
            >
              {' '}
              rendez-vous{' '}
            </a>{' '}
            nous vous aiderons.
          </p>
        </div>
        <div className="flex gap-4 mt-4 flex-col lg:flex-row">
          <Card>
            <>
              <dt className="truncate text-sm font-medium text-gray-500 flex items-center">
                <Icon
                  icon={'twemoji:key'}
                  className=" h-4 w-4 text-center mx-1"
                />
                Votre clé API
              </dt>
              <dd className="mt-1 text-1xl font-semibold tracking-tight text-gray-900">
                {apiKeys.data?.at(0)?.key}
              </dd>
            </>
          </Card>
          <Card>
            <>
              <dt className="truncate text-sm font-medium text-gray-500 flex items-center">
                <Icon
                  icon={'twemoji:coin'}
                  className=" h-4 w-4 text-center mr-1"
                />
                Vos crédits
              </dt>
              <dd className="mt-1 text-1xl font-semibold tracking-tight text-gray-900">
                {user.data?.credit}
              </dd>
            </>
          </Card>
        </div>
        {/* <div className="my-8 flex w-full flex-wrap justify-center gap-y-4 xl:justify-start">
            Pour generer une facture avec notre api voici la{' '}
            <a
              href="https://doc.pamplemousse.app"
              target="_blank"
              rel="noreferrer"
              className="mx-1"
            >
              doc
            </a>
          </div> */}
      </div>
    </Layout>
  );
};

export default Home;
